import { j as p, T as vt, c as j, s as Tt, B as Ct, g as _t, l as De, I as Qe, w as ze, a as Ve, S as St, h as At, b as Ze, H as kt, i as Pt, k as Nt } from "./ToggleSwitch-vbqhQNsj.mjs";
import q, { useRef as O, useCallback as C, useState as B, useEffect as P, forwardRef as X, useMemo as Y, createContext as et, useContext as tt, useId as ye, useLayoutEffect as be, createElement as rt, memo as It } from "react";
import { createPortal as Et } from "react-dom";
const jt = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), Be = {}, Mt = (e, t, r, n) => {
  const a = `${e}-${t ? "currency" : "noCurrency"}-${r}-${n}`;
  let i = Be[a];
  return i || (i = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: r,
    maximumFractionDigits: r,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...n ? {} : { useGrouping: !1 }
  }), Be[a] = i), i;
};
function Ae(e, t, r = {}) {
  const n = (r == null ? void 0 : r.precision) === void 0 ? 2 : r == null ? void 0 : r.precision, o = typeof t == "number" ? t || 0 : jt(e, t), a = (r == null ? void 0 : r.thousandSeparator) ?? !0;
  return Mt(e, !(r != null && r.format), n, a).format(o).replace(/\u00A0/, " ");
}
function He(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), r = parseFloat(t) || 0;
  return Math.round(r * 100);
}
function he(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
function je(e) {
  const t = O(null), r = C(
    (n) => {
      if (t.current = n, !!e) {
        if (typeof e == "function") return e(n);
        e.current = n;
      }
    },
    [e]
  );
  return [t, r];
}
const $e = {
  width: void 0,
  height: void 0
};
function Lt(e) {
  const { ref: t, box: r = "content-box" } = e, [{ width: n, height: o }, a] = B($e), i = O({ ...$e }), c = O(void 0);
  return c.current = e.onResize, P(() => {
    if (!t || !t.current || !window || typeof window > "u" || !("ResizeObserver" in window)) return;
    const s = new ResizeObserver(([l]) => {
      if (!t.current) return;
      const u = r === "border-box" ? "borderBoxSize" : r === "device-pixel-content-box" ? "devicePixelContentBoxSize" : "contentBoxSize";
      if (!l) return;
      const x = Ke(l, u, "inlineSize"), h = Ke(l, u, "blockSize");
      if (i.current.width !== x || i.current.height !== h) {
        const g = { width: x, height: h };
        i.current.width = x, i.current.height = h, c.current ? c.current(g) : a(g);
      }
    });
    return s.observe(t.current, { box: r }), () => {
      s.disconnect();
    };
  }, [r, t]), { width: n, height: o };
}
function Ke(e, t, r) {
  var o;
  const n = e[t];
  return Array.isArray(n) && n.length > 0 ? (o = n[0]) == null ? void 0 : o[r] : n && typeof n == "object" && r in n ? n[r] : r === "inlineSize" ? e.contentRect.width : e.contentRect.height;
}
function Re(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const Ft = X(
  ({
    label: e,
    locale: t = "de",
    name: r,
    id: n,
    min: o = 0,
    onChange: a,
    onBlur: i,
    onFocus: c,
    defaultValue: s,
    value: l,
    step: u,
    children: x,
    className: h,
    hideSteps: m,
    max: g,
    ..._
  }, y) => {
    const f = Y(() => Ot(u), [u]), [w, A] = B(
      he(
        Ae(t, l ?? s ?? "", { precision: f, format: "%v", thousandSeparator: !1 }),
        f
      )
    ), I = Y(() => Re(g), [g]), F = Y(() => Re(o), [o]), [M, H] = B(null), [d, v] = je(y), E = C(
      (T) => {
        const N = Ue(T.target.value, t, f), K = He(N);
        if (typeof I < "u" && K > I || typeof F < "u" && K < F) {
          const ie = T.target.selectionStart ? T.target.selectionStart : 0;
          return H(ie), T.preventDefault();
        }
        H(T.target.selectionStart);
        let J = T.target.value;
        N.startsWith(T.target.value) || (J = N);
        const ce = He(T.target.value);
        T.target.value && !isNaN(ce) && K === ce && (a == null || a(T)), A(J);
      },
      [t, f, F, I, a]
    ), z = C(
      (T) => {
        const N = Ue(T.target.value, t, f);
        N !== T.target.value && (T.target.value = N), H(null), i == null || i(T);
      },
      [i, f, t]
    ), U = C(
      (T) => {
        H(T.target.selectionStart), c == null || c(T);
      },
      [c]
    ), S = Y(
      () => s ? { defaultValue: w } : { value: w },
      [s, w]
    );
    return P(() => {
      if (typeof s < "u") return;
      const T = l ?? s;
      if (typeof l > "u")
        return A("");
      const N = he(
        Ae(t, T, { precision: f, format: "%v", thousandSeparator: !1 }),
        f
      );
      A(N);
    }, [l, f, s, t]), P(() => {
      const T = (w == null ? void 0 : w.length) ?? 0, N = (M ?? 0) > T ? T : M;
      M === null || !d.current || document.activeElement !== d.current || d.current.setSelectionRange(N, N);
    }, [M, d, w]), /* @__PURE__ */ p.jsx(
      vt,
      {
        ..._,
        ...S,
        onFocus: U,
        onBlur: z,
        onChange: E,
        step: u,
        ref: v,
        id: n || r,
        name: r,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: j(h, { [Tt.hideSteps]: m }),
        children: x
      }
    );
  }
);
Ft.displayName = "ClientAmountField";
function Ue(e, t, r = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const n = String(e), o = he(n, r), a = Ae(t, o, { precision: r, format: "%v", thousandSeparator: !1 }), i = he(a, r);
  if (!r)
    return i;
  const c = i.search(/[.,]/);
  if (c < 0)
    return i;
  const s = r - (i.length - 1 - c);
  return i.concat("0".repeat(s));
}
function Ot(e) {
  var r;
  return e === void 0 ? void 0 : ((r = String(e).split(".")[1]) == null ? void 0 : r.length) ?? 0;
}
function D(e) {
  e.preventDefault(), e.stopPropagation();
}
const Dt = X(function({
  onKeyDown: t,
  onKeyUp: r,
  onKeyDownCapture: n,
  onKeyUpCapture: o,
  onClick: a,
  ...i
}, c) {
  const { disabled: s, busy: l } = i, u = s || l, x = u ? D : t, h = u ? D : r, m = u ? D : n, g = u ? D : o, _ = u ? D : a;
  return /* @__PURE__ */ p.jsx(
    Ct,
    {
      ...i,
      onClick: _,
      onChange: x,
      onKeyUp: h,
      onKeyDownCapture: m,
      onKeyUpCapture: g,
      ref: c
    }
  );
}), xn = X(function({ onChange: t, onClick: r, ...n }, o) {
  const { disabled: a } = n, i = a ? D : t, c = a ? D : r;
  return /* @__PURE__ */ p.jsx(_t, { ...n, onChange: i, onClick: c, ref: o });
}), zt = "_tooltipArrow_qcdrl_1", Bt = "_bottomTooltipArrow_qcdrl_32", Ht = "_topTooltipArrow_qcdrl_37", Ce = {
  tooltipArrow: zt,
  bottomTooltipArrow: Bt,
  topTooltipArrow: Ht
}, R = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right"
}, Z = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  JUSTIFY: "justify"
};
function $t({ x: e, position: t, className: r }) {
  return /* @__PURE__ */ p.jsx(
    "div",
    {
      className: j(Ce.tooltipArrow, r, {
        [Ce.bottomTooltipArrow]: t === R.BOTTOM,
        [Ce.topTooltipArrow]: t === R.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === R.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
const Kt = "_picker_yqwd0_1", Rt = "_swatch_yqwd0_7", Ut = "_activeSwatch_yqwd0_19", qt = "_inactiveSwatch_yqwd0_25", Wt = "_errorSwatch_yqwd0_30", Yt = "_swatchInner_yqwd0_34", Xt = "_activePickerSwatch_yqwd0_41", Gt = "_dropdown_yqwd0_45", Jt = "_popover_yqwd0_49", Qt = "_pickerSwatches_yqwd0_55", Vt = "_input_yqwd0_68", Zt = "_label_yqwd0_91", G = {
  picker: Kt,
  swatch: Rt,
  activeSwatch: Ut,
  inactiveSwatch: qt,
  errorSwatch: Wt,
  swatchInner: Yt,
  activePickerSwatch: Xt,
  dropdown: Gt,
  popover: Jt,
  pickerSwatches: Qt,
  input: Vt,
  label: Zt
}, er = "_dropdownContent_1n9sv_1", tr = "_dropdownTriggerButton_1n9sv_14", rr = "_scaleTransition_1n9sv_26", nr = "_scaleTransitionOpen_1n9sv_34", or = "_dropdownMenu_1n9sv_39", ar = "_dropdownMenuSeparator_1n9sv_50", cr = "_dropdownMenuItem_1n9sv_59", ae = {
  dropdownContent: er,
  dropdownTriggerButton: tr,
  scaleTransition: rr,
  scaleTransitionOpen: nr,
  dropdownMenu: or,
  dropdownMenuSeparator: ar,
  dropdownMenuItem: cr
}, ir = "_popupContainer_y7ppu_1", sr = "_spyElement_y7ppu_15", lr = "_nonFocusableOutline_y7ppu_21", Me = {
  popupContainer: ir,
  spyElement: sr,
  nonFocusableOutline: lr
}, ur = {
  a: "link",
  area: "link",
  article: "article",
  aside: "complementary",
  button: "button",
  details: "group",
  dfn: "term",
  dialog: "dialog",
  fieldset: "group",
  figure: "figure",
  footer: "contentinfo",
  form: "form",
  h1: "heading",
  h2: "heading",
  h3: "heading",
  h4: "heading",
  h5: "heading",
  h6: "heading",
  header: "banner",
  hr: "separator",
  iframe: "document",
  img: "img",
  input: null,
  // for type purposes this value needs to be included
  li: "listitem",
  main: "main",
  menu: "list",
  menuitem: "menuitem",
  meter: "meter",
  nav: "navigation",
  ol: "list",
  option: "option",
  progress: "progressbar",
  section: "region",
  select: "listbox",
  summary: "button",
  table: "table",
  tbody: "rowgroup",
  td: "cell",
  textarea: "textbox",
  tfoot: "rowgroup",
  th: "columnheader",
  thead: "rowgroup",
  tr: "row",
  ul: "list"
}, dr = {
  button: "button",
  checkbox: "checkbox",
  radio: "radio",
  range: "slider",
  text: "textbox",
  password: "textbox",
  email: "textbox",
  search: "searchbox",
  number: "spinbutton",
  tel: "textbox",
  url: "textbox",
  submit: "button",
  reset: "button",
  image: "button",
  file: "textbox"
}, ee = ["a", "button", "input", "textarea", "select", "details", "area", "label"];
function pr(e) {
  return !e || !e.trim() ? /* @__PURE__ */ new Set() : new Set(e.trim().split(/\s+/));
}
function fr(e, t) {
  return [...pr(e).add(t)].join(" ");
}
function mr(e) {
  const t = e.getAttribute("role");
  if (t) return t;
  const r = e.tagName.toLowerCase();
  if (r === "input") {
    const o = e.getAttribute("type");
    return o ? dr[o] : "textbox";
  }
  const n = ur[r];
  return n || "button";
}
function nt(e, t = ee) {
  if (!(!e || !(e instanceof HTMLElement)))
    return e.querySelectorAll(`${t.join(",")},[tabindex]:not([tabindex="-1"])`);
}
function ot(e, t = ee) {
  if (!e) return !1;
  const r = e.getAttribute("tabindex");
  if (!r && !t.includes(e.tagName.toLowerCase()) || r && parseInt(r) < 0 || e.hasAttribute("disabled")) return !1;
  const o = window.getComputedStyle(e);
  return !(o.display === "none" || o.visibility === "hidden");
}
function xe(e, t = ee) {
  if (!e || !(e instanceof HTMLElement)) return null;
  const r = e.querySelectorAll(
    `${t.join(",")}:not([tabindex="-1"]):not([disabled]):not([inert]),[tabindex]:not([tabindex="-1"]):not([disabled]):not([inert])`
  );
  for (const n of r)
    if (ot(n, t))
      return n;
  return null;
}
function Le(e, t = ee) {
  return !e || !(e instanceof HTMLElement) ? null : ot(e, t) ? e : xe(e, t);
}
function at(e, t, r = ee) {
  if (!t || !(t instanceof HTMLElement)) return null;
  let n = t, o = null;
  for (; (n = e(n)) && !(o = Le(n, r)); )
    ;
  return o;
}
function ct(e, t = ee) {
  return at((r) => (r == null ? void 0 : r.nextElementSibling) ?? null, e, t);
}
function br(e, t = ee) {
  return at((r) => (r == null ? void 0 : r.previousElementSibling) ?? null, e, t);
}
function it(e, t = ee) {
  if (!e || !(e instanceof HTMLElement)) return null;
  let r = e;
  for (; ; ) {
    const n = ct(r, t);
    if (n) return n;
    if (r = (r == null ? void 0 : r.parentElement) ?? null, !r || r === e) return null;
  }
}
async function hr() {
  return new Promise((e) => {
    setTimeout(() => window.requestAnimationFrame(() => e()));
  });
}
const Fe = "data-popup", ke = "data-popup-id";
function qe(e) {
  let t = e;
  for (; t; ) {
    if (t != null && t.hasAttribute(Fe)) return t;
    t = (t == null ? void 0 : t.parentElement) ?? null;
  }
  return null;
}
function gr(e, t) {
  if (!t || !e) return !1;
  const r = qe(t);
  if (!r) return !1;
  const n = r.getAttribute(Fe);
  if (!n) return !1;
  if (e.getAttribute(ke) === n) return !0;
  const o = qe(e);
  return o ? !!o.querySelector(`[${ke}="${n}"]`) : !1;
}
function de(e) {
  return e ? e.clientHeight && e.scrollHeight > e.clientHeight || e.tagName === "DIALOG" ? e.tagName === "HTML" ? window : e : de(e.parentNode) : null;
}
const st = et({
  initialised: !1,
  open: !1,
  inDOM: !1,
  setInDOM: () => {
  },
  showPopup: () => {
  },
  hidePopup: () => {
  },
  togglePopup: () => {
  },
  hasClickTrigger: !1,
  hasKeypressTrigger: !1,
  hasFocusTrigger: !1,
  hasHoverTrigger: !1
});
function pe() {
  return tt(st);
}
function wr(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function lt(e) {
  return (t) => t === e || t && wr(t, e) ? t : e;
}
const $ = {
  Enter: "Enter",
  Space: " ",
  Tab: "Tab",
  Escape: "Escape",
  Down: "ArrowDown",
  Up: "ArrowUp",
  Left: "ArrowLeft",
  Right: "ArrowRight"
};
function ge() {
  const e = document.getElementById("bp-portal-target");
  if (e) return e;
  const t = document.createElement("div");
  return t.id = "bp-portal-target", t.style.position = "absolute", t.setAttribute("role", "popup"), document.body.appendChild(t), t;
}
function yr(e) {
  if (!e) return null;
  const { height: t, width: r } = e;
  return {
    height: t,
    width: r
  };
}
function xr({ triggerRect: e, id: t, onCloseTransitionEnd: r, onPopupLeave: n, children: o }) {
  const {
    popupRef: a,
    spyRef: i,
    preferredPopupPosition: c,
    alignPopup: s,
    hasHoverTrigger: l,
    Anchor: u,
    contentClassName: x,
    contentWrapperClassName: h,
    popupOpenWrapperClassName: m,
    anchorClassName: g
  } = pe(), [_, y] = B(null), [f, w] = B(null), [A, I] = B(null), [F, M] = B(!1), { open: H, hidePopup: d, popupRef: v } = pe();
  P(() => {
    if (!H) return M(!1);
    const b = window.requestAnimationFrame(() => {
      M(!0);
    });
    return () => window.cancelAnimationFrame(b);
  }, [H, M]);
  const E = C(() => {
    var k;
    const b = yr((k = a == null ? void 0 : a.current) == null ? void 0 : k.getBoundingClientRect());
    y(lt(b));
  }, [a]), z = C(() => {
    const b = de(ge()) ?? document.body, L = (b === window ? document.body : b).getBoundingClientRect();
    w(L.width), I(L.height);
  }, []), U = C(
    (b) => {
      var L;
      if (b.key !== $.Escape) return;
      b.preventDefault();
      const k = (L = i == null ? void 0 : i.current) == null ? void 0 : L.nextElementSibling;
      k == null || k.focus(), d();
    },
    [d, i]
  );
  P(() => {
    const b = a == null ? void 0 : a.current;
    if (!b) return;
    const k = nt(b);
    return k == null || k.forEach((L, Q) => {
      L.addEventListener("keydown", U);
    }), () => {
      k == null || k.forEach((L, Q) => {
        L.removeEventListener("keydown", U);
      });
    };
  }, [o, U, a]), be(() => {
    E(), z();
  }, [E, z]);
  const S = C(() => {
    window.requestAnimationFrame(() => {
      E(), z();
    });
  }, [E, z]), T = C(
    (b) => {
      l && n(b);
    },
    [n, l]
  ), N = C(
    (b) => {
      var L, Q, le;
      const k = (Q = (L = v == null ? void 0 : v.current) == null ? void 0 : L.firstElementChild) == null ? void 0 : Q.firstElementChild;
      if (b.target !== k && !((le = a == null ? void 0 : a.current) != null && le.contains(b.relatedTarget))) {
        if (gr(k, b.relatedTarget))
          return b.preventDefault();
        n(b);
      }
    },
    [n, v, a]
  );
  P(() => (window.addEventListener("resize", S), () => {
    window.removeEventListener("resize", S);
  }), [S]);
  const K = Y(() => u ? 17 : 0, [u]);
  P(() => {
    if (H) return;
    const b = a == null ? void 0 : a.current;
    if (!m) return r();
    b == null || b.addEventListener("transitionend", r);
    const k = setTimeout(r, 300);
    return () => {
      clearTimeout(k), b == null || b.removeEventListener("transitionend", r);
    };
  }, [a, H, r, m]);
  const {
    x: J,
    y: ce,
    anchorOffsetX: ie,
    position: te,
    width: Te
  } = Y(
    () => Sr({
      triggerRect: e,
      popupSize: _,
      bodyWidth: f,
      bodyHeight: A,
      anchorSize: K,
      preferredPopupPosition: c,
      alignPopup: s
    }),
    [e, _, f, A, K, c, s]
  );
  return Et(
    /* @__PURE__ */ p.jsx(
      "div",
      {
        id: t,
        className: Me.popupContainer,
        ref: a,
        onPointerLeave: T,
        onBlur: N,
        style: {
          transform: `translate3d(${J}px, ${ce}px, 0)`
        },
        children: /* @__PURE__ */ p.jsxs(
          "div",
          {
            className: j(
              h,
              m && { [m]: F }
            ),
            children: [
              u && te === R.BOTTOM && /* @__PURE__ */ p.jsx(u, { position: te, x: ie, className: g }),
              /* @__PURE__ */ p.jsx(
                "div",
                {
                  tabIndex: -1,
                  className: x,
                  style: {
                    ...s === Z.JUSTIFY ? { minWidth: Te, overflowX: "hidden" } : {}
                  },
                  [Fe]: t,
                  children: o
                }
              ),
              u && te === R.TOP && /* @__PURE__ */ p.jsx(u, { position: te, x: ie, className: g })
            ]
          }
        )
      }
    ),
    ge()
  );
}
function vr(e, t, r) {
  P(() => {
    var u;
    const n = (u = r == null ? void 0 : r.current) == null ? void 0 : u.nextElementSibling;
    if (!n) return;
    r.current.setAttribute("id", "tooltip-spy");
    const o = xe(n), a = o ?? n;
    n.setAttribute(ke, e);
    const c = a.getAttribute("aria-roledescription") ?? mr(a) ?? "button";
    a.setAttribute("role", "button"), o || (a == null || a.setAttribute("tabindex", "0"), a == null || a.classList.add(Me.nonFocusableOutline)), a.setAttribute("aria-roledescription", c);
    const s = a.getAttribute("aria-describedby");
    (s == null ? void 0 : s.includes(e)) || a.setAttribute("aria-describedby", `${fr(s, e)} tooltip-spy`);
  }, [t, e, r]);
}
function Tr(e, t, r) {
  if (!e) return null;
  const { left: n, top: o, bottom: a, width: i } = e;
  return {
    left: n,
    top: o,
    bottom: a,
    width: i,
    offsetX: r,
    offsetY: t
  };
}
function Cr({ children: e, content: t, id: r }) {
  const {
    showPopup: n,
    hidePopup: o,
    togglePopup: a,
    open: i,
    spyRef: c,
    popupRef: s,
    hasClickTrigger: l,
    hasFocusTrigger: u,
    hasHoverTrigger: x,
    hasKeypressTrigger: h,
    setInDOM: m
  } = pe(), [g, _] = B(null), y = Y(() => !!g, [g]);
  vr(r, e, c);
  const f = C(() => {
    var T;
    const d = (T = c == null ? void 0 : c.current) == null ? void 0 : T.nextSibling;
    let v = d == null ? void 0 : d.getBoundingClientRect();
    v != null && v.height || (v = void 0);
    let E = 0, z = 0;
    const U = de(ge()) ?? window;
    if (U !== window) {
      const N = U;
      E = -N.getBoundingClientRect().top + N.scrollTop, z = -N.getBoundingClientRect().left + N.scrollLeft;
    }
    const S = Tr(v, E, z);
    _(lt(S)), m(!0);
  }, [m, c]), w = C(() => {
    _(null), m(!1);
  }, [m]), A = C(() => {
    y && f();
  }, [f, y]), I = C(
    (d) => {
      const v = s == null ? void 0 : s.current;
      if (d.relatedTarget instanceof Node && (v != null && v.contains(d.relatedTarget)))
        return d.preventDefault();
      o();
    },
    [o, s]
  ), F = C(
    (d) => {
      var v, E;
      if (d.relatedTarget instanceof Node && ((E = (v = c == null ? void 0 : c.current) == null ? void 0 : v.nextSibling) != null && E.contains(d.relatedTarget)))
        return d.preventDefault();
      o();
    },
    [o, c]
  ), M = C(
    (d) => {
      var E, z, U;
      const v = d.target instanceof Node;
      v && ((E = s == null ? void 0 : s.current) != null && E.contains(d.target)) || v && ((U = (z = c == null ? void 0 : c.current) == null ? void 0 : z.nextElementSibling) != null && U.contains(d.target)) || F(d);
    },
    [F, s, c]
  ), H = C(
    (d) => {
      if (d.key === $.Enter || d.key === $.Space)
        return d.preventDefault(), a();
      if (d.key === $.Escape) return o();
    },
    [o, a]
  );
  return P(() => {
    if (l)
      return document.addEventListener("pointerdown", M), () => {
        document.removeEventListener("pointerdown", M);
      };
  }, [l, M]), P(() => {
    var v;
    if (!l) return;
    const d = (v = c == null ? void 0 : c.current) == null ? void 0 : v.nextElementSibling;
    return d == null || d.addEventListener("pointerdown", a), () => {
      d == null || d.removeEventListener("pointerdown", a);
    };
  }, [l, a, c, e]), P(() => {
    var v;
    if (!x) return;
    const d = (v = c == null ? void 0 : c.current) == null ? void 0 : v.nextElementSibling;
    return d == null || d.addEventListener("mouseover", n), d == null || d.addEventListener("mouseleave", I), () => {
      d == null || d.removeEventListener("mouseover", n), d == null || d.removeEventListener("mouseleave", I);
    };
  }, [x, I, n, c, e]), P(() => {
    var v;
    const d = (v = c == null ? void 0 : c.current) == null ? void 0 : v.nextElementSibling;
    return d == null || d.addEventListener("focusout", I), () => d == null ? void 0 : d.removeEventListener("focusout", I);
  }, [I, c, e]), P(() => {
    var v;
    if (!u) return;
    const d = (v = c == null ? void 0 : c.current) == null ? void 0 : v.nextElementSibling;
    return d == null || d.addEventListener("focus", n), d == null || d.addEventListener("focusin", n), () => {
      d == null || d.removeEventListener("focus", n), d == null || d.removeEventListener("focusin", n);
    };
  }, [u, n, e, c]), P(() => {
    var v;
    if (!h) return;
    const d = (v = c == null ? void 0 : c.current) == null ? void 0 : v.nextElementSibling;
    return d == null || d.addEventListener("keydown", H), () => {
      d == null || d.removeEventListener("keydown", H);
    };
  }, [h, H, c, e]), P(() => {
    if (!i) return;
    window.addEventListener("resize", A, { passive: !0 });
    const d = de(ge()) ?? window;
    return d.addEventListener("scroll", A, { passive: !0 }), () => {
      window.removeEventListener("resize", A), d.removeEventListener("scroll", A);
    };
  }, [i, A]), be(() => {
    A();
  }, [e, A]), P(() => {
    i && f();
  }, [i, f]), /* @__PURE__ */ p.jsxs(p.Fragment, { children: [
    /* @__PURE__ */ p.jsx("div", { ref: c, className: Me.spyElement }),
    e,
    y && /* @__PURE__ */ p.jsx(
      xr,
      {
        triggerRect: g,
        onCloseTransitionEnd: w,
        id: r,
        onPopupLeave: F,
        children: t
      }
    )
  ] });
}
function _r({
  children: e,
  content: t,
  id: r,
  triggerEvents: n = ["hover", "focus"],
  inactive: o,
  ...a
}) {
  const [i, c] = B(!1), [s, l] = B(!1), [u, x] = B(!1), h = O(null), m = O(null), g = pe(), _ = ye(), y = Y(() => r ?? _, [r]), f = Y(() => n == null ? void 0 : n.includes("click"), [n]), w = Y(() => n == null ? void 0 : n.includes("focus"), [n]), A = Y(() => n == null ? void 0 : n.includes("hover"), [n]), I = Y(() => n == null ? void 0 : n.includes("keypress"), [n]), F = C(() => {
    l(!1);
  }, []), M = C(() => {
    l(!0);
  }, []), H = C(() => {
    l((d) => !d);
  }, []);
  return P(() => (c(!0), () => {
    c(!1);
  }), []), !i || o ? e : /* @__PURE__ */ p.jsx(
    st.Provider,
    {
      value: {
        open: s,
        showPopup: M,
        hidePopup: F,
        togglePopup: H,
        spyRef: m,
        popupRef: h,
        hasClickTrigger: f,
        hasHoverTrigger: A,
        hasFocusTrigger: w,
        hasKeypressTrigger: I,
        inDOM: u,
        setInDOM: x,
        initialised: !0,
        parentContext: g,
        ...a
      },
      children: /* @__PURE__ */ p.jsx(Cr, { content: t, id: y, children: e })
    }
  );
}
function Sr({
  triggerRect: e,
  popupSize: t,
  bodyWidth: r,
  bodyHeight: n,
  originalMargin: o = 20,
  anchorSize: a = 17,
  preferredPopupPosition: i = R.TOP,
  alignPopup: c = Z.CENTER
}) {
  let s = 0, l = 0, u = 0, x = 0, h = i, m = (t == null ? void 0 : t.width) ?? 0;
  if (!e || !t || !r || !n)
    return { x: s, y: l, anchorOffsetX: x, position: h, width: m };
  let g = 0, _ = t.width / 2;
  const y = e.width / 2;
  c === Z.CENTER ? s = e.left + e.offsetX - _ + y : c === Z.LEFT || c === Z.JUSTIFY ? (s = e.left + e.offsetX, c === Z.JUSTIFY && (m = e.width, _ = y)) : c === Z.RIGHT && (s = e.left + e.offsetX + e.width - m);
  const f = t.width > r ? 0 : Math.min(o, (r - m) / 2);
  if (g = s, u = s + t.width, s < f ? s = f : u > r - f && (s -= u - (r - f)), a) {
    x = g - s + _ - a;
    const w = x + 2 * a - m;
    if (w > 0) {
      const A = Math.min(w, f);
      s += A, x -= A;
    }
  }
  return i === R.TOP ? (l = e.top + e.offsetY - t.height, l < Math.abs(e.offsetY) && (h = R.BOTTOM, l = e.bottom)) : (l = e.bottom + e.offsetY, l + t.height > n + e.offsetY && (h = R.TOP, l = e.top + e.offsetY - t.height)), { x: s, y: l, anchorOffsetX: x, position: h, width: m };
}
const Ar = ["click", "keypress"];
function vn({
  children: e,
  style: t,
  className: r,
  id: n
}) {
  return /* @__PURE__ */ p.jsxs(
    "button",
    {
      id: n,
      style: t,
      className: j(De.selectContainer, De.select, ae.dropdownTriggerButton, r),
      type: "button",
      children: [
        e,
        /* @__PURE__ */ p.jsx(Qe, { name: "arrowDown" })
      ]
    }
  );
}
function Tn({
  children: e,
  className: t,
  required: r,
  hint: n,
  id: o
}) {
  return /* @__PURE__ */ p.jsx("div", { children: e ? /* @__PURE__ */ p.jsxs("label", { htmlFor: o, className: j(ze.label, t), children: [
    /* @__PURE__ */ p.jsxs("div", { children: [
      e,
      r && /* @__PURE__ */ p.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    n ? /* @__PURE__ */ p.jsx("div", { className: j(ze.hint), children: n }) : null
  ] }) : null });
}
function Cn({ children: e, className: t }) {
  return /* @__PURE__ */ p.jsx("ul", { className: j(ae.dropdownMenu, t), children: e });
}
function _n({
  children: e,
  style: t,
  className: r
}) {
  const n = O(null), { preferredPopupPosition: o, hidePopup: a, parentContext: i, spyRef: c } = se(), s = C(
    (l) => {
      var h, m;
      const u = i && o, x = u && ut[u].close;
      if (l.key === $.Down || l.key === $.Up) {
        l.preventDefault();
        const g = l.key === $.Up ? br(n.current) : ct(n.current);
        g == null || g.focus();
        return;
      }
      l.key === x && (l.preventDefault(), (m = Le((h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling)) == null || m.focus(), a()), l.key === $.Enter && l.target instanceof HTMLElement && "click" in l.target && l.target.click();
    },
    [a, i, o, c]
  );
  return P(() => {
    const l = xe(n.current);
    if (l)
      return l.addEventListener("keydown", s), l.addEventListener("click", a), () => {
        l.removeEventListener("keydown", s), l.removeEventListener("click", a);
      };
  }, [a, s]), /* @__PURE__ */ p.jsx("li", { ref: n, style: t, className: j(ae.dropdownMenuItem, r), children: e });
}
function Sn() {
  return /* @__PURE__ */ p.jsx("li", { className: ae.dropdownMenuSeparator });
}
const ut = {
  [R.BOTTOM]: { open: $.Down, close: $.Down },
  [R.TOP]: { open: $.Up, close: $.Down },
  [R.RIGHT]: { open: $.Right, close: $.Left },
  [R.LEFT]: { open: $.Left, close: $.Right }
};
function kr({ children: e }) {
  const { spyRef: t, popupRef: r, showPopup: n, preferredPopupPosition: o } = se(), a = C(
    (i) => {
      const c = o ?? R.BOTTOM, s = ut[c].open;
      i.key === s && (i.preventDefault(), n(), hr().then(() => {
        var u;
        let l = xe(r == null ? void 0 : r.current);
        if (l) {
          l.focus();
          return;
        }
        i.key === $.Tab && (l = it((u = t == null ? void 0 : t.current) == null ? void 0 : u.nextElementSibling), l == null || l.focus());
      }));
    },
    [r, o, n, t]
  );
  return P(() => {
    var c;
    const i = (c = t == null ? void 0 : t.current) == null ? void 0 : c.nextElementSibling;
    if (i)
      return i.addEventListener("keydown", a), () => i.removeEventListener("keydown", a);
  }, [a, t]), e;
}
function Pr({ children: e }) {
  const { spyRef: t, popupRef: r, hidePopup: n, open: o } = se(), a = C(
    (c) => {
      var s, l;
      c.key !== $.Tab || !c.shiftKey || (c.preventDefault(), (l = Le((s = t == null ? void 0 : t.current) == null ? void 0 : s.nextElementSibling)) == null || l.focus(), n());
    },
    [t, n]
  ), i = C(
    (c) => {
      var l;
      if (c.key !== $.Tab || c.shiftKey) return;
      c.preventDefault();
      const s = it((l = t == null ? void 0 : t.current) == null ? void 0 : l.nextElementSibling);
      s == null || s.focus();
    },
    [t]
  );
  return P(() => {
    const c = r == null ? void 0 : r.current;
    if (!c) return;
    const s = nt(c);
    if (!s || !s.length) return;
    const l = s.item(0), u = s.item(s.length - 1);
    return l.addEventListener("keydown", a), u.addEventListener("keydown", i), () => {
      l.removeEventListener("keydown", a), u.removeEventListener("keydown", i);
    };
  }, [a, i, t, r, e, o]), e;
}
function Nr({
  trigger: e,
  children: t,
  className: r,
  alignContent: n = Z.JUSTIFY,
  wrapperClassName: o,
  anchorClassName: a,
  preferredPopupPosition: i,
  Anchor: c
}) {
  const { parentContext: s } = se(), l = i ?? (s ? s.preferredPopupPosition ?? R.RIGHT : R.BOTTOM);
  return /* @__PURE__ */ p.jsx(
    _r,
    {
      content: /* @__PURE__ */ p.jsx(Pr, { children: t }),
      contentClassName: j(ae.dropdownContent, r),
      contentWrapperClassName: j(ae.scaleTransition, o),
      popupOpenWrapperClassName: ae.scaleTransitionOpen,
      triggerEvents: Ar,
      preferredPopupPosition: l,
      alignPopup: n,
      anchorClassName: a,
      Anchor: c,
      children: /* @__PURE__ */ p.jsx(kr, { children: e })
    }
  );
}
function se() {
  const {
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: o,
    inDOM: a,
    parentContext: i,
    alignPopup: c,
    preferredPopupPosition: s
  } = pe(), l = i && i.initialised ? i : void 0;
  return {
    open: a,
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: o,
    parentContext: l,
    alignPopup: c,
    preferredPopupPosition: s
  };
}
function ve() {
  return (ve = Object.assign || function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = arguments[t];
      for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
    }
    return e;
  }).apply(this, arguments);
}
function Oe(e, t) {
  if (e == null) return {};
  var r, n, o = {}, a = Object.keys(e);
  for (n = 0; n < a.length; n++) t.indexOf(r = a[n]) >= 0 || (o[r] = e[r]);
  return o;
}
function we(e) {
  var t = O(e), r = O(function(n) {
    t.current && t.current(n);
  });
  return t.current = e, r.current;
}
var fe = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = 1), e > r ? r : e < t ? t : e;
}, ue = function(e) {
  return "touches" in e;
}, Pe = function(e) {
  return e && e.ownerDocument.defaultView || self;
}, We = function(e, t, r) {
  var n = e.getBoundingClientRect(), o = ue(t) ? function(a, i) {
    for (var c = 0; c < a.length; c++) if (a[c].identifier === i) return a[c];
    return a[0];
  }(t.touches, r) : t;
  return { left: fe((o.pageX - (n.left + Pe(e).pageXOffset)) / n.width), top: fe((o.pageY - (n.top + Pe(e).pageYOffset)) / n.height) };
}, Ye = function(e) {
  !ue(e) && e.preventDefault();
}, dt = q.memo(function(e) {
  var t = e.onMove, r = e.onKey, n = Oe(e, ["onMove", "onKey"]), o = O(null), a = we(t), i = we(r), c = O(null), s = O(!1), l = Y(function() {
    var m = function(y) {
      Ye(y), (ue(y) ? y.touches.length > 0 : y.buttons > 0) && o.current ? a(We(o.current, y, c.current)) : _(!1);
    }, g = function() {
      return _(!1);
    };
    function _(y) {
      var f = s.current, w = Pe(o.current), A = y ? w.addEventListener : w.removeEventListener;
      A(f ? "touchmove" : "mousemove", m), A(f ? "touchend" : "mouseup", g);
    }
    return [function(y) {
      var f = y.nativeEvent, w = o.current;
      if (w && (Ye(f), !function(I, F) {
        return F && !ue(I);
      }(f, s.current) && w)) {
        if (ue(f)) {
          s.current = !0;
          var A = f.changedTouches || [];
          A.length && (c.current = A[0].identifier);
        }
        w.focus(), a(We(w, f, c.current)), _(!0);
      }
    }, function(y) {
      var f = y.which || y.keyCode;
      f < 37 || f > 40 || (y.preventDefault(), i({ left: f === 39 ? 0.05 : f === 37 ? -0.05 : 0, top: f === 40 ? 0.05 : f === 38 ? -0.05 : 0 }));
    }, _];
  }, [i, a]), u = l[0], x = l[1], h = l[2];
  return P(function() {
    return h;
  }, [h]), q.createElement("div", ve({}, n, { onTouchStart: u, onMouseDown: u, className: "react-colorful__interactive", ref: o, onKeyDown: x, tabIndex: 0, role: "slider" }));
}), pt = function(e) {
  return e.filter(Boolean).join(" ");
}, ft = function(e) {
  var t = e.color, r = e.left, n = e.top, o = n === void 0 ? 0.5 : n, a = pt(["react-colorful__pointer", e.className]);
  return q.createElement("div", { className: a, style: { top: 100 * o + "%", left: 100 * r + "%" } }, q.createElement("div", { className: "react-colorful__pointer-fill", style: { backgroundColor: t } }));
}, oe = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = Math.pow(10, t)), Math.round(r * e) / r;
}, Ir = function(e) {
  var t = e.s, r = e.v, n = e.a, o = (200 - t) * r / 100;
  return { h: oe(e.h), s: oe(o > 0 && o < 200 ? t * r / 100 / (o <= 100 ? o : 200 - o) * 100 : 0), l: oe(o / 2), a: oe(n, 2) };
}, Ne = function(e) {
  var t = Ir(e);
  return "hsl(" + t.h + ", " + t.s + "%, " + t.l + "%)";
};
q.memo(function(e) {
  var t = e.hue, r = e.onChange, n = pt(["react-colorful__hue", e.className]);
  return q.createElement("div", { className: n }, q.createElement(dt, { onMove: function(o) {
    r({ h: 360 * o.left });
  }, onKey: function(o) {
    r({ h: fe(t + 360 * o.left, 0, 360) });
  }, "aria-label": "Hue", "aria-valuenow": oe(t), "aria-valuemax": "360", "aria-valuemin": "0" }, q.createElement(ft, { className: "react-colorful__hue-pointer", left: t / 360, color: Ne({ h: t, s: 100, v: 100, a: 1 }) })));
});
q.memo(function(e) {
  var t = e.hsva, r = e.onChange, n = { backgroundColor: Ne({ h: t.h, s: 100, v: 100, a: 1 }) };
  return q.createElement("div", { className: "react-colorful__saturation", style: n }, q.createElement(dt, { onMove: function(o) {
    r({ s: 100 * o.left, v: 100 - 100 * o.top });
  }, onKey: function(o) {
    r({ s: fe(t.s + 100 * o.left, 0, 100), v: fe(t.v - 100 * o.top, 0, 100) });
  }, "aria-label": "Color", "aria-valuetext": "Saturation " + oe(t.s) + "%, Brightness " + oe(t.v) + "%" }, q.createElement(ft, { className: "react-colorful__saturation-pointer", top: 1 - t.v / 100, left: t.s / 100, color: Ne(t) })));
});
var Er = /^#?([0-9A-F]{3,8})$/i, jr = function(e) {
  var t = e.color, r = t === void 0 ? "" : t, n = e.onChange, o = e.onBlur, a = e.escape, i = e.validate, c = e.format, s = e.process, l = Oe(e, ["color", "onChange", "onBlur", "escape", "validate", "format", "process"]), u = B(function() {
    return a(r);
  }), x = u[0], h = u[1], m = we(n), g = we(o), _ = C(function(f) {
    var w = a(f.target.value);
    h(w), i(w) && m(s ? s(w) : w);
  }, [a, s, i, m]), y = C(function(f) {
    i(f.target.value) || h(a(r)), g(f);
  }, [r, a, i, g]);
  return P(function() {
    h(a(r));
  }, [r, a]), q.createElement("input", ve({}, l, { value: c ? c(x) : x, spellCheck: "false", onChange: _, onBlur: y }));
}, Xe = function(e) {
  return "#" + e;
}, Mr = function(e) {
  var t = e.prefixed, r = e.alpha, n = Oe(e, ["prefixed", "alpha"]), o = C(function(i) {
    return i.replace(/([^0-9A-F]+)/gi, "").substring(0, r ? 8 : 6);
  }, [r]), a = C(function(i) {
    return function(c, s) {
      var l = Er.exec(c), u = l ? l[1].length : 0;
      return u === 3 || u === 6 || !!s && u === 4 || !!s && u === 8;
    }(i, r);
  }, [r]);
  return q.createElement(jr, ve({}, n, { escape: o, format: t ? Xe : void 0, process: Xe, validate: a }));
};
const Lr = {
  "betterplace-dimension-0": "1rem",
  "betterplace-dimension-1": "1.25rem",
  "betterplace-dimension-2": "1.5rem",
  "betterplace-dimension-3": "2rem",
  "betterplace-dimension-4": "3rem",
  "betterplace-dimension-5": "4rem",
  "betterplace-dimension-6": "5rem",
  "betterplace-dimension-7": "6.25rem",
  "betterplace-dimension-8": "12.5rem",
  "betterplace-dimension-9": "20rem",
  "betterplace-dimension-10": "30rem",
  "betterplace-dimension--1": "0.75rem",
  "betterplace-dimension--2": "0.5rem",
  "betterplace-dimension--3": "0.25rem",
  "betterplace-dimension--4": "0.125rem",
  "betterplace-dimension--5": "0.0625rem",
  "betterplace-dimension-none": "0rem",
  "betterplace-dimension-negative-1": "-0.75rem",
  "betterplace-dimension-negative-2": "-0.5rem",
  "betterplace-dimension-negative-3": "-0.25rem",
  "betterplace-dimension-negative-4": "-0.125rem",
  "betterplace-dimension-negative-5": "-0.0625rem",
  "betterplace-opacity-low": "10%",
  "betterplace-opacity-md": "50%",
  "betterplace-opacity-high": "90%",
  "betterplace-letter-spacing-0": "0%",
  "betterplace-letter-spacing-increased": "150%",
  "betterplace-letter-spacing-decreased": "-5%",
  "betterplace-paragraph-spacing-0": "0",
  "betterplace-paragraph-spacing-md": "0.75rem",
  "betterplace-paragraph-spacing-sm": "0.5rem",
  "betterplace-color-gray-100": "#f8f8f8",
  "betterplace-color-gray-200": "#eeeeee",
  "betterplace-color-gray-300": "#cccccc",
  "betterplace-color-gray-400": "#acacac",
  "betterplace-color-gray-500": "#919191",
  "betterplace-color-gray-600": "#7b7b7b",
  "betterplace-color-gray-700": "#636363",
  "betterplace-color-gray-800": "#3f3f3f",
  "betterplace-color-gray-900": "#282828",
  "betterplace-color-green-100": "#f5fccc",
  "betterplace-color-green-200": "#eaf99a",
  "betterplace-color-green-300": "#d6ef66",
  "betterplace-color-green-400": "#bedf40",
  "betterplace-color-green-500": "#9ecb0a",
  "betterplace-color-green-600": "#85ac1c",
  "betterplace-color-green-700": "#549205",
  "betterplace-color-green-800": "#357c00",
  "betterplace-color-green-900": "#2a5807",
  "betterplace-color-purple-100": "#f7dae8",
  "betterplace-color-purple-200": "#f0b7d8",
  "betterplace-color-purple-300": "#d387ba",
  "betterplace-color-purple-400": "#a75c96",
  "betterplace-color-purple-500": "#833c79",
  "betterplace-color-purple-600": "#6d2c64",
  "betterplace-color-purple-700": "#612058",
  "betterplace-color-purple-800": "#4d1a43",
  "betterplace-color-purple-900": "#381835",
  "betterplace-color-teal-100": "#e4fbee",
  "betterplace-color-teal-200": "#c9f7e2",
  "betterplace-color-teal-300": "#a7e7d0",
  "betterplace-color-teal-400": "#88d0bd",
  "betterplace-color-teal-500": "#60b2a4",
  "betterplace-color-teal-600": "#309b89",
  "betterplace-color-teal-700": "#2b8475",
  "betterplace-color-teal-800": "#1e6761",
  "betterplace-color-teal-900": "#114e47",
  "betterplace-color-yellow-100": "#fff4d2",
  "betterplace-color-yellow-200": "#fbe8af",
  "betterplace-color-yellow-300": "#f9da7c",
  "betterplace-color-yellow-400": "#f6ce46",
  "betterplace-color-yellow-500": "#f3b700",
  "betterplace-color-yellow-600": "#f3a900",
  "betterplace-color-yellow-700": "#f39600",
  "betterplace-color-yellow-800": "#d86c00",
  "betterplace-color-yellow-900": "#c44400",
  "betterplace-color-red-100": "#fee2e2",
  "betterplace-color-red-200": "#ffc4c4",
  "betterplace-color-red-300": "#fca5a5",
  "betterplace-color-red-400": "#f87171",
  "betterplace-color-red-500": "#ef4444",
  "betterplace-color-red-600": "#dc2626",
  "betterplace-color-red-700": "#d32b43",
  "betterplace-color-red-800": "#b11b3e",
  "betterplace-color-red-900": "#931c38",
  "betterplace-color-light": "#ffffff",
  "betterplace-color-darkness": "#000000",
  "betterplace-color-blue-pruple-500": "#7d83ff",
  "betterplace-color-blue-purple-600": "#4557cb",
  "betterplace-color-blue-dark": "#245d8c",
  "betterplace-color-red-alt": "#fc440f",
  "betterplace-color-cream": "#fbfbf2",
  "betterplace-color-opacity-white-40": "rgba(255, 255, 255, 0.4)",
  "betterplace-color-opacity-white-70": "rgba(255, 255, 255, 0.7)",
  "betterplace-color-opacity-black-10": "rgba(0, 0, 0, 0.1)",
  "betterplace-color-opacity-black-20": "rgba(0, 0, 0, 0.2)",
  "betterplace-color-opacity-black-30": "rgba(0, 0, 0, 0.3)",
  "betterplace-color-opacity-black-40": "rgba(0, 0, 0, 0.4)",
  "betterplace-color-opacity-black-50": "rgba(0, 0, 0, 0.5)",
  "betterplace-color-opacity-black-60": "rgba(0, 0, 0, 0.6)",
  "betterplace-color-opacity-black-70": "rgba(0, 0, 0, 0.7)",
  "betterplace-color-opacity-black-80": "rgba(0, 0, 0, 0.8)",
  "betterplace-color-opacity-black-90": "rgba(0, 0, 0, 0.9)",
  "betterplace-me-color-orange-500": "#ff854d",
  "betterplace-me-color-orange-600": "#DB6038",
  "betterplace-me-color-orange-800": "#6F1F06",
  "betterplace-me-color-blue-100": "#E0F4F9",
  "betterplace-me-color-blue-500": "#00A0C8",
  "betterplace-me-color-blue-700": "#005D90",
  "betterplace-me-color-green-special": "#EBF2E6",
  "betterplace-external-color-blue-facebook": "#4E69A2",
  "betterplace-external-color-blue-twitter": "#1DA1F2",
  "betterplace-external-color-pink-instagram": "#E65083",
  "betterplace-external-color-blue-linkedin": "#0A66C2",
  "betterplace-external-color-red-sparkasse": "#ff0000",
  "betterplace-font-families-fira-sans": "Fira Sans",
  "betterplace-font-weights-regular": 400,
  "betterplace-font-weights-bold": 700,
  "betterplace-text-case-none": "none",
  "betterplace-text-case-capitalize": "capitalize",
  "betterplace-text-decoration-none": "none",
  "betterplace-text-decoration-underline": "underline",
  "betterplace-line-heights-xs": 1.1,
  "betterplace-line-heights-sm": 1.2,
  "betterplace-line-heights-base": 1.5,
  "betterplace-variables-min-font-size": "1rem",
  "betterplace-variables-max-font-size": "1.125rem",
  "betterplace-variables-fluid-root-font-size": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-variables-font-size-scale-ratio": "1.1892",
  "betterplace-variables-width-of-zero-glyph": "0.5583",
  "betterplace-units-ch": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem) * 0.5583",
  "betterplace-units-rem": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-font-size-0": "1rem",
  "betterplace-font-size-1": "1.189rem",
  "betterplace-font-size-2": "1.414rem",
  "betterplace-font-size-3": "1.682rem",
  "betterplace-font-size-4": "2rem",
  "betterplace-font-size-5": "2.378rem",
  "betterplace-font-size-6": "2.828rem",
  "betterplace-font-size-7": "3.363rem",
  "betterplace-font-size-8": "4rem",
  "betterplace-font-size-9": "4.757rem",
  "betterplace-font-size-10": "5.657rem",
  "betterplace-font-size--3": "0.595rem",
  "betterplace-font-size--2": "0.707rem",
  "betterplace-font-size--1": "0.878rem",
  "betterplace-measure-2xs": "25ch",
  "betterplace-measure-xs": "36ch",
  "betterplace-measure-sm": "49ch",
  "betterplace-measure-md": "64ch",
  "betterplace-measure-lg": "81ch",
  "betterplace-measure-xl": "100ch",
  "betterplace-measure-2xl": "121ch",
  "betterplace-viewport-width": "90rem",
  "betterplace-viewport-min": "22.5rem",
  "betterplace-viewport-max": "90rem",
  "betterplace-typography-xs": {
    fontWeight: 400,
    fontSize: "0.707rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-narrow": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold-narrow": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base": {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base-bold": {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg": {
    fontWeight: 400,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg-bold": {
    fontWeight: 700,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl": {
    fontWeight: 400,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl-bold": {
    fontWeight: 700,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-2xl-bold": {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-3xl-bold": {
    fontWeight: 700,
    fontSize: "3.363rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-4xl-bold": {
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-sizing-fix-1": "20rem",
  "betterplace-sizing-fix-2": "23.4375rem",
  "betterplace-sizing-fix-3": "48.75rem",
  "betterplace-sizing-fix-4": "60rem",
  "betterplace-sizing-fix-5": "52.875rem",
  "betterplace-sizing-fix-6": "90rem",
  "betterplace-shadow-outer-neutral-soft-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-green-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-red-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-1": {
    x: "0rem",
    y: "-0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-2": {
    x: "0rem",
    y: "-0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-3": {
    x: "0rem",
    y: "-0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-4": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-5": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-6": {
    x: "0rem",
    y: "-0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-red-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-green-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#2a5807"
  }
}, Fr = (e) => e.substring(1, 3) === "00" && e.length === 9 ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEX////Z5exdL3Q7AAAAGklEQVQY02NgAAFGRkaa0gzo4gzo6hgGiTsANBAAYf1ZdsIAAAAASUVORK5CYII=')" : e;
function W(e) {
  const t = Lr[e];
  return typeof t != "object" && typeof t != "number" ? t : "#";
}
const mt = X(function({
  onClick: t,
  color: r,
  triggerSwatch: n = !1,
  className: o,
  "aria-describedby": a,
  ...i
}, c) {
  return /* @__PURE__ */ p.jsxs(
    "button",
    {
      ...i,
      className: j(G.swatch, o, {
        [G.activeSwatch]: n
      }),
      onClick: t,
      ref: c,
      type: "button",
      "aria-roledescription": n ? "colour well" : "button",
      "aria-describedby": a,
      children: [
        /* @__PURE__ */ p.jsx(
          "div",
          {
            style: {
              background: Fr(r)
            },
            className: G.swatchInner
          }
        ),
        /* @__PURE__ */ p.jsx("span", { className: "sr-only", children: r })
      ]
    }
  );
});
function Or({
  color: e,
  presetColors: t,
  onChange: r,
  hexInputLabel: n,
  label: o,
  id: a,
  classNames: i,
  error: c,
  ...s
}) {
  const l = ye(), u = a || s.name || l, x = O(null), h = O(null);
  return /* @__PURE__ */ p.jsx(
    Ve,
    {
      name: s.name,
      id: u,
      label: o,
      error: c,
      className: j(G.picker, i == null ? void 0 : i.label),
      errorDisplay: "border",
      children: /* @__PURE__ */ p.jsx(
        Nr,
        {
          className: G.dropdown,
          trigger: /* @__PURE__ */ p.jsx(
            mt,
            {
              ...s,
              "aria-describedby": `label-${u}`,
              id: u,
              color: e,
              ref: h,
              triggerSwatch: !0,
              className: j(i == null ? void 0 : i.trigger, { [G.errorSwatch]: c })
            }
          ),
          Anchor: $t,
          children: /* @__PURE__ */ p.jsx("div", { className: G.popover, ref: x, children: /* @__PURE__ */ p.jsxs("div", { className: G.pickerSwatches, children: [
            t.map((m) => /* @__PURE__ */ rt(
              Dr,
              {
                ...s,
                key: m,
                color: m,
                onClick: () => {
                  r(m);
                },
                className: j({
                  [G.activeSwatch]: m === e,
                  [G.inactiveSwatch]: m !== e
                })
              }
            )),
            /* @__PURE__ */ p.jsx(zr, { id: u, color: e, onChange: r, alpha: !0, prefixed: !0, className: G.input })
          ] }) })
        }
      )
    }
  );
}
function Dr({ onClick: e, ...t }) {
  const { hidePopup: r } = se(), n = C(
    (o) => {
      r(), e == null || e(o);
    },
    [e, r]
  );
  return /* @__PURE__ */ p.jsx(mt, { ...t, onClick: n });
}
function zr({ onKeyDown: e, ...t }) {
  const { hidePopup: r } = se(), n = C(
    (o) => {
      e == null || e(o), o.key === "Enter" && r();
    },
    [e, r]
  );
  return /* @__PURE__ */ p.jsx(Mr, { ...t, onKeyDown: n });
}
function An({
  onColorChange: e,
  transparentOption: t = !1,
  value: r,
  hexInputLabel: n,
  label: o,
  ...a
}) {
  const i = [
    W("betterplace-color-light"),
    W("betterplace-color-green-500"),
    W("betterplace-color-green-700"),
    W("betterplace-color-green-900"),
    W("betterplace-color-yellow-400"),
    W("betterplace-me-color-orange-500"),
    W("betterplace-color-red-700"),
    W("betterplace-color-red-900"),
    // themeColor('orange-700'),
    // themeColor('blue-400'),
    // themeColor('blue-700'),
    // themeColor('blue-900'),
    W("betterplace-color-purple-400"),
    W("betterplace-color-purple-700"),
    W("betterplace-color-gray-500"),
    W("betterplace-color-gray-700"),
    W("betterplace-color-gray-900")
  ].filter(Boolean);
  return t && i.push("#00000000"), /* @__PURE__ */ p.jsx(
    Or,
    {
      ...a,
      color: `#${r === "transparent" ? "00000000" : r}`,
      presetColors: i,
      onChange: e,
      hexInputLabel: n,
      label: o
    }
  );
}
function Ie(e, t, r, n, o) {
  return (e - t) * (o - n) / (r - t) + n;
}
function bt(e, t) {
  const r = e + t;
  return r > 360 ? r - 360 : r;
}
function ht() {
  return Math.random() > 0.5;
}
const gt = [0, 0, 1], Ee = [
  // dual axis rotations (a bit more realistic)
  [1, 1, 0],
  [1, 0, 1],
  [0, 1, 1],
  // single axis rotations (a bit dumber)
  [1, 0, 0],
  [0, 1, 0],
  gt
];
function Br([e, t, r]) {
  const [n, o, a] = gt;
  return n === e && o === t && a === r;
}
function kn(e) {
  return !!Ee[e] && !Br(Ee[e]) && ht();
}
function ne(e, t = 4) {
  const r = Math.pow(10, t);
  return Math.round(e * r) / r;
}
function wt(e) {
  return ne(e, 2).toString().replace(".", "-");
}
const Ge = 200, Hr = 800, $r = 0.8, Kr = 0.33, Je = 0.5, Pn = Ee.reduce((e, t, r) => ({
  ...e,
  [`--confetti-rotation-3d-${r}-50`]: t.map((n) => n / 2).join(),
  [`--confetti-rotation-3d-${r}-100`]: t.join()
}), {});
function Nn(e, t, r) {
  const o = {
    "--confetti-landing-point-y": typeof t == "string" ? t : `${t}px`
  };
  for (const a of e) {
    const i = `${ne(Ie(Math.abs(bt(a, 90) - 180), 0, 180, -r / 2, r / 2), 2)}px`;
    o[`--confetti-degree-${wt(a)}-landing-point-x`] = i;
  }
  return o;
}
function In({ color: e, degree: t }, r, n, o, a) {
  const i = Math.round(Math.random() * (Hr - Ge) + Ge), c = r - Math.round(Math.random() * 1e3), l = Math.random() < $r ? ne(Math.random() * Kr, 2) : 0, u = l * -1, x = l, h = ne(Math.abs(Ie(Math.abs(bt(t, 90) - 180), 0, 180, -1, 1)), 4), m = ne(Math.random() * Je, 4), g = ne(Math.random() * n * (ht() ? 1 : -1), 4), _ = Je, y = ne(Math.max(Ie(Math.abs(t - 180), 0, 180, n, -n), 0), 4);
  return {
    "--confetti-animation-duration": `${c}ms`,
    "--confetti-particle-width": `${a ? o : Math.round(Math.random() * 4) + o / 2}px`,
    "--confetti-particle-height": `${a ? o : Math.round(Math.random() * 2) + o}px`,
    "--confetti-particle-color": e,
    "--confetti-rotation-animation-duration": `${i}ms`,
    "--confetti-landing-point-x": `var(--confetti-degree-${wt(t)}-landing-point-x)`,
    "--confetti-x1": l,
    "--confetti-x2": u,
    "--confetti-x3": x,
    "--confetti-x4": h,
    "--confetti-y1": m,
    "--confetti-y2": g,
    "--confetti-y3": _,
    "--confetti-y4": y
  };
}
const En = X(function({ onChange: t, onClick: r, ...n }, o) {
  const { disabled: a } = n, i = a ? D : t, c = a ? D : r;
  return /* @__PURE__ */ p.jsx(St, { ...n, onChange: i, onClick: c, ref: o });
}), Rr = X(function({
  onKeyDown: t,
  onKeyUp: r,
  onKeyDownCapture: n,
  onKeyUpCapture: o,
  onClick: a,
  ...i
}, c) {
  const { disabled: s } = i, l = s ? D : t, u = s ? D : r, x = s ? D : n, h = s ? D : o, m = s ? D : a;
  return /* @__PURE__ */ p.jsx(
    At,
    {
      ...i,
      onClick: m,
      onChange: l,
      onKeyUp: u,
      onKeyDownCapture: x,
      onKeyUpCapture: h,
      ref: c
    }
  );
}), Ur = "_accordion_1upte_1", qr = "_accordionItem_1upte_6", Wr = "_content_1upte_11", Yr = "_wrapper_1upte_16", Xr = "_accordionSummary_1upte_26", Gr = "_accordionIcon_1upte_70", Jr = "_open_1upte_88", Qr = "_closing_1upte_101", re = {
  accordion: Ur,
  accordionItem: qr,
  content: Wr,
  wrapper: Yr,
  accordionSummary: Xr,
  accordionIcon: Gr,
  open: Jr,
  closing: Qr
}, yt = et({
  activeKey: void 0,
  prevKey: void 0,
  onTransitionFinished: (e) => {
  }
});
function Vr() {
  return tt(yt);
}
function jn({
  label: e,
  id: t,
  className: r,
  children: n
}) {
  const { activeKey: o, onTransitionFinished: a, prevKey: i } = Vr(), [c, s] = B(o === t || i === t), l = O(null), u = O(null), x = C(
    (m) => {
      m.propertyName === "opacity" && m.target === l.current && o === t && (a(t), window.requestAnimationFrame(() => {
        if (!u.current) return;
        const g = de(u.current);
        if (!g) return;
        const { top: _ } = u.current.getBoundingClientRect(), y = g === window ? 0 : g.offsetTop;
        g.scrollBy({ behavior: "smooth", top: _ - y });
      }));
    },
    [o, t, a]
  );
  P(() => {
    if (o === i) return;
    const m = setTimeout(() => s(o === t), 1);
    return () => clearTimeout(m);
  }, [t, o, i]);
  const h = C((m) => {
    m.key !== "Tab" && m.key !== " " && m.key !== "Enter" && D(m);
  }, []);
  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    /* @__PURE__ */ p.jsxs(
      "details",
      {
        open: !0,
        id: t,
        "aria-expanded": o === t ? "true" : "false",
        className: j(re.accordionItem, { [re.open]: c }, r),
        ref: u,
        children: [
          /* @__PURE__ */ p.jsxs(
            "summary",
            {
              onClick: D,
              onKeyDown: h,
              className: re.accordionSummary,
              tabIndex: -1,
              children: [
                e,
                /* @__PURE__ */ p.jsx(Qe, { name: "arrowDown", className: re.accordionIcon, size: "400" })
              ]
            }
          ),
          /* @__PURE__ */ p.jsx("div", { className: re.wrapper, onTransitionEnd: x, ref: l, children: /* @__PURE__ */ p.jsx("div", { className: re.content, inert: o === t ? !1 : "true", children: n }) })
        ]
      }
    )
  );
}
function Mn({
  defaultKey: e,
  children: t,
  activeKey: r,
  onTransitionFinished: n,
  className: o
}) {
  const [a, i] = B(void 0), [c, s] = B(r ?? e), l = C(
    (u) => {
      n == null || n(u, a), i(u);
    },
    [n, a]
  );
  return P(() => {
    r && (s(r), i(c));
  }, [r]), /* @__PURE__ */ p.jsx(Ze, { className: j(re.accordion, o), children: /* @__PURE__ */ p.jsx(yt.Provider, { value: { activeKey: c, onTransitionFinished: l, prevKey: a }, children: t }) });
}
const Zr = "_modal_xnbvu_1", en = "_modalInner_xnbvu_52", tn = "_modalHeader_xnbvu_56", rn = "_closeButton_xnbvu_71", nn = "_modalContent_xnbvu_77", on = "_modalActions_xnbvu_81", an = "_portalTarget_xnbvu_90", cn = "_portalTargetInert_xnbvu_97", V = {
  modal: Zr,
  modalInner: en,
  modalHeader: tn,
  closeButton: rn,
  modalContent: nn,
  modalActions: on,
  portalTarget: an,
  portalTargetInert: cn
};
function sn(e) {
  e && (!("close" in e) || typeof e.close != "function" || e.close());
}
const Ln = X(function({
  id: t,
  isOpen: r,
  onClose: n,
  onOpen: o,
  onCloseComplete: a,
  onOpenComplete: i,
  title: c,
  customActions: s,
  children: l,
  manualFocusRef: u,
  closeText: x,
  closeOnClickOutside: h = !0,
  hideHeader: m = !1,
  hideActions: g = !1,
  classNames: _ = {}
}, y) {
  const [f, w] = je(y), A = O(null), [I, F] = B(!0), M = C(() => u || (A.current ? A : f), [u, f]), H = ye(), d = t || H, v = C(
    (S) => {
      var J;
      if (S.key === "Escape") return n(S);
      if (S.key !== "Tab") return;
      const T = (J = f.current) == null ? void 0 : J.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      ), N = T == null ? void 0 : T[0], K = T == null ? void 0 : T[T.length - 1];
      if (S.shiftKey && document.activeElement === N) {
        S.preventDefault(), K == null || K.focus();
        return;
      }
      !S.shiftKey && document.activeElement === K && (S.preventDefault(), N == null || N.focus());
    },
    [f, n]
  ), E = C(() => {
    a == null || a(), F(!0);
  }, [a]), z = C(() => {
    var S;
    i == null || i(), F(!0), (S = f.current) == null || S.removeAttribute("inert");
  }, [f, i]);
  P(() => {
    var T;
    F(!1);
    const S = f.current;
    if (S) {
      if (r) {
        S.showModal();
        const N = M();
        (T = N == null ? void 0 : N.current) == null || T.focus(), S.removeEventListener("transitionend", E), S.addEventListener("keydown", v), S.addEventListener("transitionend", z), o == null || o();
      } else
        S.removeEventListener("keydown", v), S.removeEventListener("transitionend", z), S.addEventListener("transitionend", E), sn(S), S.setAttribute("inert", "");
      return () => {
        S == null || S.removeEventListener("keydown", v), S.removeEventListener("transitionend", E), S.removeEventListener("transitionend", z);
      };
    }
  }, [M, v, r, f, E, o, z]);
  const U = C(
    (S) => {
      const { current: T } = f;
      S.target === T && n(S);
    },
    [f, n]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ p.jsxs(
      "dialog",
      {
        id: d,
        ref: w,
        className: j(V.modal, _.modal),
        onCancel: n,
        onClick: h ? U : void 0,
        "aria-labelledby": `modal-title-${d}`,
        "aria-modal": "true",
        title: c,
        tabIndex: -1,
        children: [
          /* @__PURE__ */ p.jsxs(Ze, { space: "400", className: j(V.modalInner, _.modalInner), children: [
            !m && /* @__PURE__ */ p.jsxs("div", { className: j(V.modalHeader, _.titleContainer), children: [
              /* @__PURE__ */ p.jsx(kt, { level: "h200", as: "h2", id: `modal-title-${d}`, children: c }),
              /* @__PURE__ */ p.jsx(
                Rr,
                {
                  kind: "plain",
                  className: V.closeButton,
                  onClick: n,
                  ref: A,
                  type: "button",
                  iconName: "x",
                  iconProps: { title: x, color: "fg-content-primary" }
                }
              )
            ] }),
            /* @__PURE__ */ p.jsx("div", { className: j(V.modalContent, _.contentContainer), children: (r || !I) && l }),
            !g && /* @__PURE__ */ p.jsx("div", { className: j(V.modalActions, _.actionsContainer), children: s || /* @__PURE__ */ p.jsx(Dt, { onClick: n, type: "button", children: x }) })
          ] }),
          r && /* @__PURE__ */ p.jsxs(p.Fragment, { children: [
            /* @__PURE__ */ p.jsx("div", { id: "bp-portal-target", className: V.portalTarget, role: "alert" }),
            /* @__PURE__ */ p.jsx("div", { id: "bp-portal-target-inert", className: V.portalTargetInert, role: "alert" })
          ] })
        ]
      }
    )
  );
}), Fn = X(function({ onChange: t, onClick: r, ...n }, o) {
  const { disabled: a } = n, i = a ? D : t, c = a ? D : r;
  return /* @__PURE__ */ p.jsx(Pt, { ...n, onChange: i, onClick: c, ref: o });
}), ln = "_input_1bj67_1", un = "_growWrap_1bj67_2", me = {
  input: ln,
  growWrap: un,
  "input--error": "_input--error_1bj67_29"
}, On = X(function({
  label: t,
  name: r,
  description: n,
  error: o,
  warning: a,
  id: i,
  required: c,
  className: s,
  onChange: l,
  errorDisplay: u,
  rows: x = 1,
  fullWidth: h,
  ...m
}, g) {
  const _ = O(null), y = [];
  return o && y.push(`${r}-error`), a && y.push(`${r}-warning`), n && y.push(`${r}-description`), /* @__PURE__ */ p.jsx(
    Ve,
    {
      label: t,
      name: r,
      description: n,
      error: o,
      warning: a,
      id: i || r,
      required: c,
      errorDisplay: u,
      fullWidth: h,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ p.jsx(
        "div",
        {
          className: j(me.growWrap, s, {
            [me.wrapError]: !!o,
            fullWidth: h && h !== "mobile-only",
            fullWidthOnMobile: h && h === "mobile-only"
          }),
          ref: _,
          children: /* @__PURE__ */ p.jsx(
            "textarea",
            {
              ...m,
              ref: g,
              id: i || r,
              name: r,
              "aria-describedby": y.length ? y.join(" ") : void 0,
              "aria-invalid": !!o,
              "aria-required": c,
              className: j(me.input, s, {
                [me["input--error"]]: !!o,
                fullWidth: h && h !== "mobile-only",
                fullWidthOnMobile: h && h === "mobile-only"
              }),
              rows: x,
              onChange: (f) => {
                var w;
                (w = _.current) == null || w.setAttribute("data-replicated-value", f.target.value), l && l(f);
              }
            }
          )
        }
      )
    }
  );
}), dn = "_button_1ijcr_1", pn = "_hiddenInput_1ijcr_58", _e = {
  button: dn,
  hiddenInput: pn,
  "size-large": "_size-large_1ijcr_64",
  "size-default": "_size-default_1ijcr_69",
  "size-small": "_size-small_1ijcr_74"
}, Dn = X(function({
  id: t,
  children: r,
  value: n,
  name: o,
  onChange: a,
  onClick: i,
  className: c,
  type: s = "radio",
  size: l = "default",
  disabled_: u,
  disabled: x,
  fullWidth: h,
  ...m
}, g) {
  const _ = j(_e.button, _e[`size-${l}`], c), y = ye(), w = `${t || o || y}-${String(n)}`, A = x || u;
  return /* @__PURE__ */ p.jsxs(
    "label",
    {
      htmlFor: w,
      className: j(
        {
          fullWidth: h && h !== "mobile-only",
          fullWidthOnMobile: h && h === "mobile-only"
        },
        _
      ),
      children: [
        /* @__PURE__ */ p.jsx(
          "input",
          {
            ...m,
            ref: g,
            id: w,
            "aria-disabled": A,
            type: s,
            value: n,
            name: o,
            onClick: A ? D : i,
            onChange: A ? D : a,
            className: _e.hiddenInput,
            disabled: u
          }
        ),
        r
      ]
    }
  );
}), zn = X(function({ onChange: t, onClick: r, ...n }, o) {
  const { disabled: a } = n, i = a ? D : t, c = a ? D : r;
  return /* @__PURE__ */ p.jsx(Nt, { ...n, onChange: i, onClick: c, ref: o });
});
function fn(e, t, r) {
  return {
    position: "absolute",
    inset: 0,
    transform: `translateY(${e * (t + r)}px)`,
    height: `${t}px`
  };
}
const Se = {
  Up: "Up",
  Down: "Down",
  None: null
};
function Bn(e) {
  const [t, r] = B(!1);
  return P(() => (r(!0), () => {
    r(!1);
  }), []), t ? /* @__PURE__ */ p.jsx(xt, { ...e }) : null;
}
function mn(e, t, r, n) {
  const o = e + t, a = r * o, i = n % o;
  return a + i;
}
function bn(e, t) {
  return e === t ? !1 : !e && t || !t && e ? !0 : Object.keys(t).some((r) => t[r] !== e[r]);
}
function hn(e) {
  const [t, r] = B(e);
  return P(() => {
    bn(t, e) && r(e);
  }, [e]), t;
}
const xt = X(function({
  className: t,
  style: r,
  children: n,
  overscanRows: o,
  maxRows: a,
  rowHeight: i = 0,
  rows: c,
  onScroll: s,
  initialRow: l,
  gap: u = 0,
  reactiveProps: x
}, h) {
  const m = hn(x), [g, _] = je(h), y = o || c, f = O(/* @__PURE__ */ new Map()), [w, A] = B(i), I = O(null), F = O((l ?? 0) * w), [M, H] = B(0), d = O(0), v = O(Se.None), E = a ?? M + (c + y) * 2 + 1, z = c * (w + u), U = (w + u) * E, { width: S } = Lt({ ref: g });
  be(() => {
    var k, L;
    const b = (L = (k = g.current) == null ? void 0 : k.firstElementChild) == null ? void 0 : L.firstElementChild;
    b && A(b.clientHeight);
  }, [g, S, m]);
  const T = `${w}px`;
  function N(b) {
    var k;
    return (!f.current.has(b) || ((k = f.current.get(b)) == null ? void 0 : k.height) !== T) && f.current.set(b, fn(b, w, u)), f.current.get(b);
  }
  const K = C(
    (b) => {
      const k = Math.max(0, b - y), L = Math.min(
        E - 1,
        // don't render past the end of the list
        b + c + y
      );
      return [k, L, b];
    },
    [E, y, c]
  ), J = [], [ce, ie] = K(M);
  for (let b = ce; b <= ie; b++)
    J.push(/* @__PURE__ */ p.jsx(n, { index: b, style: N(b) }, b));
  const te = C(
    (b) => {
      I.current !== null && clearTimeout(I.current), I.current = setTimeout(() => {
        s == null || s(b), I.current = null;
      }, 50);
    },
    [s]
  );
  P(() => {
    d.current = M;
  }, [M]), be(() => {
    var L, Q, le;
    const b = ((L = g.current) == null ? void 0 : L.scrollTop) ?? 0, k = mn(w, u, M, b);
    (le = (Q = g.current) == null ? void 0 : Q.scrollTo) == null || le.call(Q, { left: 0, top: k, behavior: "instant" }), s == null || s(K(M));
  }, [w, u, E]);
  const Te = C(
    (b) => {
      const k = F.current;
      F.current = b.currentTarget.scrollTop;
      const L = Math.floor(F.current / (w + u));
      v.current = k < F.current ? Se.Down : Se.Up, te(K(L)), H(L);
    },
    [K, te, w, u]
  );
  return P(() => {
    var b, k;
    l && ((k = (b = g.current) == null ? void 0 : b.scrollTo) == null || k.call(b, { left: 0, top: l * (w + u), behavior: "instant" }), s == null || s(K(l)));
  }, [K, l, s, w, u, g]), P(() => () => {
    I != null && I.current && clearTimeout(I.current);
  }, []), /* @__PURE__ */ p.jsx(
    "div",
    {
      className: t,
      style: {
        // allow to override the height
        height: z,
        ...r,
        overflowY: "auto",
        position: "relative",
        scrollBehavior: "smooth",
        willChange: "contents",
        maxHeight: z
      },
      ref: _,
      onScroll: Te,
      children: /* @__PURE__ */ p.jsxs("div", { style: { position: "absolute", top: 0, left: 0, right: 0, height: U }, children: [
        /* @__PURE__ */ rt(n, { ...m, index: -1, key: -1, style: { opacity: "0", pointerEvents: "none" } }),
        J
      ] })
    }
  );
}), Hn = It(xt);
export {
  Z as A,
  br as B,
  Ft as C,
  Nr as D,
  it as E,
  hr as F,
  Fe as G,
  ke as H,
  qe as I,
  gr as J,
  de as K,
  Mn as L,
  st as M,
  pe as N,
  Fn as O,
  _r as P,
  Dn as Q,
  zn as R,
  Bn as S,
  On as T,
  je as U,
  Hn as V,
  Lt as W,
  $ as X,
  In as a,
  Ee as b,
  $t as c,
  R as d,
  Dt as e,
  xn as f,
  Nn as g,
  An as h,
  Cn as i,
  _n as j,
  Sn as k,
  vn as l,
  Tn as m,
  En as n,
  Rr as o,
  jn as p,
  Ln as q,
  Pn as r,
  kn as s,
  fr as t,
  Vr as u,
  mr as v,
  nt as w,
  xe as x,
  Le as y,
  ct as z
};
